import {AnyObject} from '@v2/types/general';
import {debounce} from '@v2/utils/debounce';

export type BrowserHistoryListener = (location: {previous: URL; current: URL}) => unknown;

export class BrowserHistory implements History {
  private location: URL;
  private globalHistory: History;
  private subscribers = new Set<BrowserHistoryListener>();
  private debouncedPublish = debounce(this.publish, 50);

  constructor(history: History) {
    this.location = this.getCurrentLocation();
    this.globalHistory = history;
    this.listenEvents();
  }

  private getCurrentLocation() {
    return new URL(window.location.href);
  }

  private listenEvents() {
    window.addEventListener('popstate', () => this.handleLocationChange());
  }

  private handleLocationChange() {
    const previous = this.location;
    const current = this.getCurrentLocation();

    if (previous.href !== current.href) {
      this.debouncedPublish({previous, current});
      this.location = current;
    }
  }

  get length(): number {
    return this.globalHistory.length;
  }

  get scrollRestoration() {
    return this.globalHistory.scrollRestoration;
  }
  set scrollRestoration(value: ScrollRestoration) {
    this.globalHistory.scrollRestoration = value;
  }

  get state() {
    return this.globalHistory.state;
  }

  back(): void {
    this.globalHistory.back();
    this.handleLocationChange();
  }

  forward(): void {
    this.globalHistory.forward();
    this.handleLocationChange();
  }

  go(delta?: number | undefined): void {
    this.globalHistory.go(delta);
    this.handleLocationChange();
  }

  pushState(
    data: AnyObject,
    unused: string,
    url?: string | URL | null | undefined
  ): void {
    this.globalHistory.pushState(data, unused, url);
    this.handleLocationChange();
  }

  replaceState(
    data: AnyObject,
    unused: string,
    url?: string | URL | null | undefined
  ): void {
    this.globalHistory.replaceState(data, unused, url);
    this.handleLocationChange();
  }

  subscribe(
    cb: (location: {previous: URL; current: URL}) => unknown
  ): () => boolean {
    this.subscribers.add(cb);
    return () => this.unsubscribe(cb);
  }

  unsubscribe(
    cb: (location: {previous: URL; current: URL}) => unknown
  ): boolean {
    return this.subscribers.delete(cb);
  }

  publish(location: {previous: URL; current: URL}) {
    this.subscribers.forEach(fn => fn(location));
  }
}

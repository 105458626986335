import {BrowserAgent} from '@newrelic/browser-agent/loaders/browser-agent';
import {
  SESSION_PROVIDER_TAG, 
  SessionProvider,
} from '@v2/components/providers/session_provider';
import {Nullable} from '@v2/types/general';
import {FeatureFlagKey} from './feature_flags/FeatureFlag';

type NewRelicFeatureFlags = Extract<
  FeatureFlagKey,
  | 'sessionTracing'
  | 'pageViewTiming'
>;

const NEW_RELIC_FFS: Readonly<NewRelicFeatureFlags[]> = ['sessionTracing', 'pageViewTiming'] as const;

const newRelicFeatureFlagMap: Record<NewRelicFeatureFlags, Record<string, unknown>> = {
  sessionTracing: {session_trace: {enable: true}},
  pageViewTiming: {page_view_timing: {enable: true}},
}

/**
 * Default options to use with NewRelic's browser agent.
 * (Note: There are hard-coded keys here; they are meant to be transparent, like this, and 
 * were originally provided by NewRelic in a copy/past snippet.)
 */
const DEFAULT_OPTIONS = {
  init: {
    distributed_tracing: {enabled: true},
    privacy: {cookies_enabled: true},
    ajax:{deny_list: ['bam.nr-data.net']},
    spa: {enabled: false},
    jserrors: {enabled: true},
    page_view_timing: {enabled: false},
    session_trace: {enabled: false},
  }, 
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: 'NRJS-ee7b77ff151750241a4',
    applicationID: '601491203',
    sa:1,
  },
  loader_config: {
    accountID: '2954041',
    trustKey: '2954041',
    agentID: '601491203',
    licenseKey: 'NRJS-ee7b77ff151750241a4',
    applicationID: '601491203',
  },
} as const;

/**
 * Wrapper class for NewRelic's browser agent that allow us to configure setings at runtime.
 */
export class NewRelic {
  private static _session: Nullable<SessionProvider> = null;

  private constructor() {}

  private static getSession() {
    if (this._session) return this._session;

    const provider = document.querySelector(SESSION_PROVIDER_TAG);

    if (!provider) {
      console.warn('There is currently no session available.');
      return;
    }

    return this._session = provider;
  };

  static init() {
    const options = {...DEFAULT_OPTIONS};

    this.getSession()?.addEventListener('rendered', () => {
      const {flags} = this.getSession()!.model;
      
      for (const flag of NEW_RELIC_FFS) {
        if (flags.enabled(flag)) {
          options.init = {...options.init, ...newRelicFeatureFlagMap[flag]};
        }
      }

      new BrowserAgent(options);
    });
  }
}

import {LitElement} from 'lit';
import {Nullable} from '@v2/types/general';
import {ConnectedItem} from '@v2/types/state_controller';
import {isNotNill} from '@v2/utils';
import {isLitElement} from '@v2/utils/dom';
import {namespaceIdGenerator} from '@v2/utils/generators';
import {ListenDisconnectController} from '@v2/controllers/state_controller/listen_disconnect_controller';
import {
  StoreItemId,
  IStateControllerStore,
  StoreItem,
} from '@v2/types/state_controller/store';

type ConnectedElement<T extends LitElement = LitElement> = T;

type StoreDescriptionRegistry<
  TValue,
  TStore extends Record<string, unknown>
> = Map<StoreItemId, StoreItem<TValue, TStore>>;
type StoreItemRegistry<TStore extends Record<string, unknown>> = Map<
  ConnectedItem,
  Map<keyof TStore, Set<StoreItemId>>
>;
type StoreKeyRegistry<TStore extends Record<string, unknown>> = Map<
  keyof TStore,
  Set<StoreItemId>
>;
type StoreValueRegistry<TValue, TStore extends Record<string, unknown>> = Map<
  TValue,
  Map<keyof TStore, StoreItemId>
>;
type ElementControllerRegistry<TElement extends ConnectedElement> = Map<
  TElement,
  ListenDisconnectController
>;

export class StateControllerStore<
  TValue,
  TStore extends Record<string, unknown>
> implements IStateControllerStore<TValue, TStore> {
  private registryIdGenerator: IterableIterator<string>;
  private storeDescriptionRegistry: StoreDescriptionRegistry<
    TValue,
    TStore
  > = new Map();
  private storeItemRegistry: StoreItemRegistry<TStore> = new Map();
  private storeKeyRegistry: StoreKeyRegistry<TStore> = new Map();
  private storeValueRegistry: StoreValueRegistry<TValue, TStore> = new Map();
  private elementControllerRegistry: ElementControllerRegistry<ConnectedElement> = new Map();

  constructor(name: string) {
    this.registryIdGenerator = namespaceIdGenerator(name);
  }

  private getRegistryId(): StoreItemId {
    return this.registryIdGenerator.next().value;
  }

  private removeStoreItemFromUnregisterItem(item: ConnectedItem): void {
    const itemsMap = this.storeItemRegistry.get(item);

    itemsMap?.forEach(idSet => {
      idSet.forEach(storeItemId => {
        const storeItem = this.storeDescriptionRegistry.get(storeItemId);
        if (!storeItem) return;
        this.removeFromAllRegistries(storeItem);
      });
    });
  }

  private removeFromAllRegistries(params: {
    storeItemId: StoreItemId;
    item: ConnectedItem;
    key: keyof TStore;
    value: TValue;
  }) {
    const {storeItemId, item, key, value} = params;
    this.removeFromStoreKeyRegistry(storeItemId, key);
    this.removeFromStoreValueRegistry(key, value);
    this.removeFromStoreDescriptionRegistry(storeItemId);
    this.removeFromStoreItemRegistry(storeItemId, item, key);
  }

  private registerIntoStoreKeyRegistry(
    storeItemId: StoreItemId,
    key: keyof TStore
  ): void {
    const set = this.storeKeyRegistry.get(key) ?? new Set();
    set.add(storeItemId);
    this.storeKeyRegistry.set(key, set);
  }

  private removeFromStoreKeyRegistry(
    storeItemId: StoreItemId,
    key: keyof TStore
  ): void {
    const set = this.storeKeyRegistry.get(key) ?? new Set();
    set?.delete(storeItemId);
    if (set?.size === 0) {
      this.storeKeyRegistry.delete(key);
    }
  }

  private registerIntoStoreItemRegistry<Key extends keyof TStore>(
    storeItemId: StoreItemId,
    item: ConnectedItem,
    key: Key
  ): void {
    this.register(item);
    if (!this.storeItemRegistry.has(item)) {
      this.storeItemRegistry.set(item, new Map());
    }
    const set = this.storeItemRegistry.get(item)?.get(key) ?? new Set();
    set.add(storeItemId);
    this.storeItemRegistry.get(item)?.set(key, set);
  }

  private removeFromStoreItemRegistry<Key extends keyof TStore>(
    storeItemId: StoreItemId,
    item: ConnectedItem,
    action: Key
  ): void {
    const map = this.storeItemRegistry.get(item);
    const set = map?.get(action);
    set?.delete(storeItemId);
    if (set?.size === 0) {
      map?.delete(action);
    }
  }

  private registerIntoStoreValueRegistry<Key extends keyof TStore>(
    storeItemId: StoreItemId,
    action: Key,
    value: TValue
  ): void {
    const map = this.storeValueRegistry.get(value) ?? new Map();
    if (map.has(action)) {
      console.error(
        `Trying to override callback ${map.get(
          action
        )} on the ${action.toString()} action.`
      );
      return;
    }
    map.set(action, storeItemId);
    this.storeValueRegistry.set(value, map);
  }

  private removeFromStoreValueRegistry<Key extends keyof TStore>(
    action: Key,
    value: TValue
  ): void {
    const map = this.storeValueRegistry.get(value);
    map?.delete(action);
    if (map?.size === 0) {
      this.storeValueRegistry.delete(value);
    }
  }

  private registerIntoStoreDescriptionRegistry<Key extends keyof TStore>(
    storeItemId: StoreItemId,
    item: ConnectedItem,
    key: Key,
    value: TValue
  ): void {
    this.storeDescriptionRegistry.set(storeItemId, {
      storeItemId,
      item,
      key,
      value,
    });
  }

  private removeFromStoreDescriptionRegistry(storeItemId: StoreItemId): void {
    this.storeDescriptionRegistry.delete(storeItemId);
  }

  private registerIntoElementControllerRegistry(item: ConnectedItem): void {
    if (this.storeItemRegistry.has(item)) return;
    if (!isLitElement(item)) return;
    this.elementControllerRegistry.set(
      item,
      new ListenDisconnectController(item, () => this.unregister(item))
    );
  }

  private removeFromElementControllerRegistry(element: ConnectedElement): void {
    if (!this.storeItemRegistry.has(element)) return;
    const controller = this.elementControllerRegistry.get(element);
    controller && element.removeController(controller);
    this.elementControllerRegistry.delete(element);
  }

  register(item: ConnectedItem): void {
    this.registerIntoElementControllerRegistry(item);
  }

  unregister(item: ConnectedItem): void {
    if (isLitElement(item)) {
      this.removeFromElementControllerRegistry(item);
    }
    this.removeStoreItemFromUnregisterItem(item);
  }

  saveStoreItem<Key extends keyof TStore>(
    item: ConnectedItem,
    key: Key,
    value: TValue
  ): VoidFunction {
    const storeItemId = this.getRegistryId();

    this.registerIntoStoreItemRegistry(storeItemId, item, key);
    this.registerIntoStoreKeyRegistry(storeItemId, key);
    this.registerIntoStoreValueRegistry(storeItemId, key, value);
    this.registerIntoStoreDescriptionRegistry(storeItemId, item, key, value);

    return () => this.deleteStoreItem(item, key, value);
  }

  deleteStoreItem<Key extends keyof TStore>(
    item: ConnectedItem,
    key: Key,
    value: TValue
  ): boolean {
    const storeItemId = this.storeValueRegistry.get(value)?.get(key);
    if (!storeItemId) return false;
    const storeItem = this.storeDescriptionRegistry.get(storeItemId);
    if (!storeItem) return false;
    if (storeItem.item !== item) return false;
    this.removeFromStoreItemRegistry(storeItemId, item, key);
    this.removeFromStoreKeyRegistry(storeItemId, key);
    this.removeFromStoreValueRegistry(key, value);
    this.removeFromStoreDescriptionRegistry(storeItemId);
    return true;
  }

  hasStoreItems(): boolean {
    return this.storeDescriptionRegistry.size > 0;
  }

  getAllStoreItems(): StoreItem<TValue, TStore>[] {
    return Array.from(this.storeDescriptionRegistry.values());
  }

  getStoreItemsById(storeItemId: string): Nullable<StoreItem<TValue, TStore>> {
    const storeItem = this.storeDescriptionRegistry.get(storeItemId);
    return storeItem ?? null;
  }

  getStoreItemsByKey(key: keyof TStore): StoreItem<TValue, TStore>[] {
    const set = this.storeKeyRegistry.get(key);
    if (!set) return [];
    return Array.from(set.values())
      .map(storeItemId => {
        return this.getStoreItemsById(storeItemId);
      })
      .filter(isNotNill);
  }

  getStoreItemByValue(
    value: TValue,
    key: keyof TStore
  ): Nullable<StoreItem<TValue, TStore>> {
    const map = this.storeValueRegistry.get(value);
    if (!map) return null;
    const storeItemId = map.get(key);
    if (!storeItemId) return null;
    return this.getStoreItemsById(storeItemId);
  }

  getStoreItemByItem(
    item: ConnectedItem,
    key: keyof TStore
  ): StoreItem<TValue, TStore>[] {
    const map = this.storeItemRegistry.get(item);
    if (!map) return [];
    const storeItemIds = map.get(key);
    if (!storeItemIds) return [];
    return Array.from(storeItemIds.values())
      .map(storeItemId => {
        return this.getStoreItemsById(storeItemId);
      })
      .filter(isNotNill);
  }
}

import {AnyObject} from '@v2/types/general';
import {isNil, isPlainObject} from '@v2/utils';
import {checkEquality} from '@v2/utils/arrays';
import {assert} from '@v2/utils/assert';
import {hasProperty} from '@v2/utils/objects';
import {createId} from '@v2/utils/uuid';

/**
 * This file creates all logic need to extends ImmutableComponent Model
 * is a way to allow internal computed properties, i.e.,
 * properties which values depends exclusively on
 * other model properties
 *
 * Example on how to use it:
 *
 * type ModelA = {
 *   a: number;
 *   b: number;
 *   c: Computed<number>;
 *   d: Computed<string>;
 *   e: Computed<number>;
 * };
 *
 * // ...inside init function
 * const a = createModel<ModelA>(compute => ({
 *   a: 1,
 *   b: 2,
 *   c: compute(model => model.a + model.b),
 *   d: compute(model => String(model.c * 2)),
 *   e: compute(model => model.a * 2),
 * }));
 *
 * createModel is backwards compatible,
 * so you can use it like this to:
 *
 * type ModelB = {a: number; b: number};
 * // ...inside init function
 * const b = createModel<ModelB>({a: 1, b: 2});
 *
 */

/**
 * Symbol used to mark the model as computable for future processing.
 * @private
 */
const COMPUTABLE_MODEL_SYMBOL = Symbol('computed-model');

/**
 * Symbol used to mark computed properties in the model.
 * @private
 */
const COMPUTED_SYMBOL = Symbol('computed-property');
/**
 * Symbol used to represent uncached computation in the cache.
 * @private
 */
const UNCACHED_COMPUTATION_SYMBOL = Symbol('uncached-computation-symbol');

/**
 * Type forcing a "nominal typing" of a computed property.
 * @property {symbol} __kind__ - Symbol identifying the computed property.
 */
type ComputableModelBrand = {__kind__: typeof COMPUTABLE_MODEL_SYMBOL};

/**
 * Type forcing a "nominal typing" of a computed property.
 * @property {symbol} __kind__ - Symbol identifying the computed property.
 */
type ComputedBrand = {__kind__: typeof COMPUTED_SYMBOL};

/**
 * Type representing a computed property with a nullable or undefined type.
 * @property {T} inferredType - The null or undefined property type.
 * @property {symbol} __kind__ - Symbol identifying the computed property.
 */
type NullishComputedBrand<T extends null | undefined> = {
  inferredType: T;
} & ComputedBrand;

/**
 * Type representing the valid keys for a computation based on model properties.
 */
type _ComputationValidKeys<Model, ResponseType> = {
  [K in keyof Model]: Model[K] extends ComputedBrand
    ? UnfoldComputed<Model[K]> extends ResponseType
      ? K
      : never
    : never;
}[keyof Model];

/**
 * Options for the compute function.
 */
type ComputeFnOptions<Model> = {
  /**
   * Specifies whether to ignore assertion errors when computing the value.
   */
  ignoreAssert?: boolean;
  /**
   * A function that returns a string
   * or number used for caching the computed value.
   * If the returned value is the same for subsequent computations,
   * the cached value will be used instead.
   */
  cacheCheck?: (model: Model) => unknown[];
};

/**
 * Type representing the computation function
 * used in creating a computed property.
 *
 * @returns {Function} - A function that creates
 * a computation for a specific property.
 */
type ComputeFn<Model extends Record<string, unknown>> = () => <
  Key extends ComputableKey<Model>,
  Response extends UnfoldComputed<Model[Key]>
>(
  callback: (model: ComputedModel<Model>) => Response,
  options?: ComputeFnOptions<Model>
) => (key: Key) => Computation<Model, Key>;

/**
 * Type representing a computed property in the model.
 *
 * @property {Key} key - The key of the computed property.
 * @property {Function} callback - The callback function for computing the property.
 * @property {ComputedBrand} __kind__ - The computed property brand.
 */
export type Computation<Model, Key extends keyof Model> = {
  key: Key;
  callback: (
    model: Omit<ComputedModel<Model>, Key>
  ) => UnfoldComputed<Model[Key]>;
  configuration: ComputeFnOptions<Model>;
} & ComputedBrand;

/**
 * Type representing a model with computed properties.
 *
 * @typedef {Object} ComputableModel
 */
export type ComputableModel<Model> = Model extends Record<string, unknown>
  ? {
      [K in keyof Model]: Model[K] extends ComputedBrand
        ? (key: K) => Computation<Model, K>
        : UnfoldComputed<Model[K]>;
    }
  : Model;

/**
 * Type representing any model with computed properties.
 */
export type AnyComputableModel = ComputableModel<Record<string, unknown>>;

/**
 * Type representing the valid keys for a computation based on model properties.
 */
type ComputableKey<Model extends Record<string, unknown>> = {
  [K in keyof Model]: Model[K] extends Computed<unknown> ? K : never;
}[keyof Model];

/**
 * Type representing a computed property with a nullable or undefined type.
 *
 * @property {T} inferredType - The inferred type of the computed property.
 * @property {symbol} __kind__ - Symbol identifying the computed property.
 */
export type Computed<T> = T extends null | undefined
  ? NullishComputedBrand<T>
  : T & ComputedBrand;

/**
 * Type representing the unwrapped type of a computed property.
 */
type UnfoldComputed<T> = T extends NullishComputedBrand<infer Y>
  ? Y
  : T extends infer U & ComputedBrand
  ? U
  : T;

/**
 * Type representing a model with unwrapped computed properties.
 */
export type ComputedModel<T> = T extends Record<string, unknown>
  ? {
      [K in keyof T]: UnfoldComputed<T[K]>;
    }
  : T;

/**
 * Type representing an internal model computation.
 *
 * @property {Key} key - The key of the computed property.
 * @property {Array<Key>} dependencies -
 *  The properties the computed property must use to calculate its value.
 * @property {Function} compute - The computation function for the property.
 */
export type InternalModelComputation<
  Model extends Record<string, unknown>,
  Key extends keyof Model
> = {
  key: Key;
  dependencies: Array<PropertyKey[]>;
  compute: (model: Model) => UnfoldComputed<Model[Key]>;
};

/**
 * Checks if an object has the computable model brand.
 * Used to avoid longer processing on simple models,
 * as ComputableModel are, at least, O(n).
 *
 * Models are normally just a few properties so individually
 * it won't impact performance. But if we run it for every model
 * of every component in the application, we can have a (small) penalty.
 *
 * @private
 */
function hasComputableModelBrand<T>(
  value: T
): value is T & ComputableModelBrand {
  return (
    value !== null &&
    typeof value === 'object' &&
    '__kind__' in value &&
    typeof value.__kind__ === 'symbol' &&
    value.__kind__ === COMPUTABLE_MODEL_SYMBOL
  );
}

/**
 * Checks if an object has the computed property brand.
 *
 * @param {Object} value - The object to check.
 * @returns {boolean} -
 *  Returns true if the object has the computed property brand;
 * otherwise, returns false.
 * @private
 */
function hasComputedBrand<T>(value: T): value is T & ComputedBrand {
  return (
    value !== null &&
    typeof value === 'object' &&
    '__kind__' in value &&
    typeof value.__kind__ === 'symbol' &&
    value.__kind__ === COMPUTED_SYMBOL
  );
}

/**
 * Adds the computed property brand to an object.
 *
 * @param {Object} value - The object to add the brand to.
 * @returns {Object} - The object with the added computed property brand.
 * @private
 */
function addComputedBrandProperty<T>(value: T): T & ComputedBrand {
  if (hasProperty(value, '__kind__')) {
    if (hasComputedBrand(value)) return value;
    throw new TypeError(`Can't reassign __kind__ to JSON.stringify(${value})`);
  }

  Object.defineProperty(value, '__kind__', {
    value: COMPUTED_SYMBOL,
    writable: false,
    configurable: false,
    enumerable: false,
  });

  return value as T & ComputedBrand;
}

/**
 * Checks if a value is a valid computation definition.
 *
 * @param {unknown} value - The value to check.
 * @returns {boolean} - Returns true if the value is a valid computation;
 * otherwise, returns false.
 * @private
 */
function isComputation<Model, Key extends keyof Model>(
  value: unknown
): value is Computation<Model, Key> {
  return hasComputedBrand(value);
}

/**
 * Creates a model with computable properties.
 *
 * @param {ComputableModel|Function} modelOrFn -
 *  The model or function to create the computable model.
 * @returns {ComputableModel} - The created computable model.
 */
export function createModel<Model extends Record<string, unknown>>(
  model: ComputableModel<Model>
): ComputableModel<Model>;
export function createModel<Model extends Record<string, unknown>>(
  fn: (compute: ReturnType<ComputeFn<Model>>) => ComputableModel<Model>
): ComputableModel<Model> & ComputableModelBrand;
export function createModel<Model extends Record<string, unknown>>(
  modelOrFn:
    | ComputableModel<Model>
    | ((compute: ReturnType<ComputeFn<Model>>) => ComputableModel<Model>)
): ComputableModel<Model> | (ComputableModel<Model> & ComputableModelBrand) {
  let modelHasComputableProperty = false;

  function compute<
    Key extends ComputableKey<Model>,
    Response extends UnfoldComputed<Model[Key]>
  >(
    callback: (model: Omit<ComputableModel<Model>, Key>) => Response,
    options?: ComputeFnOptions<Model>
  ) {
    modelHasComputableProperty = true;
    return (key: Key): Computation<Model, Key> => {
      return addComputedBrandProperty({
        key,
        callback: callback as Computation<Model, Key>['callback'],
        configuration: {
          ignoreAssert: !!options?.ignoreAssert,
          cacheCheck: options?.cacheCheck,
        },
      });
    };
  }

  if (typeof modelOrFn !== 'function') {
    return modelOrFn;
  }

  const computableModel = modelOrFn(compute as ReturnType<ComputeFn<Model>>);

  if (modelHasComputableProperty) {
    Object.defineProperty(computableModel, '__kind__', {
      value: COMPUTABLE_MODEL_SYMBOL,
      writable: false,
      configurable: false,
      enumerable: false,
    });
  }

  return computableModel;
}

/**
 * Creates a model computation definition, to be used on update runs
 *
 * @param {Computation} definition - The computed property definition.
 * @param {Array<Key>} dependencies - The dependencies of the computed property.
 * @returns {InternalModelComputation} - The model computation definition.
 * @private
 */
function createInternalModelComputationDefinition<
  Model extends Record<string, unknown>,
  Key extends keyof Model
>(
  definition: Computation<Model, Key>,
  dependencies: Array<PropertyKey[]>
): InternalModelComputation<Model, Key> {
  return {
    key: definition.key,
    dependencies,
    compute: runComputation(
      definition.key,
      dependencies,
      (model: Model) => {
        // Force type so the model appears without the own key
        return definition.callback(
          (model as unknown) as Omit<ComputedModel<Model>, Key>
        );
      },
      definition.configuration
    ),
  };
}

type ComputeContext = {
  computationKey: PropertyKey;
  dependencies: Array<PropertyKey[]>;
  cancelled: boolean;
  unresolvedProperty?: PropertyKey;
};

type ComputeContextStack = ComputeContext[];

/**
 * Initializes a computable model. This function processes a given computable model object,
 * extracts and computes the values for its computed properties, and returns a new object
 * containing the fully computed model and instructions for future computations.
 *
 * @param {Model} computableModel - The computable model to initialize.
 * @returns {Object} - An object containing the computed model and computation instructions.
 *
 * @template Model - The type of the model object.
 * @typedef {Object} ComputedModelResult
 * @property {Readonly<ComputedModel<Model>>} computedModel - The fully computed model.
 * @property {Array<InternalModelComputation<Model, keyof Model>>} computationInstructions -
 *   An array of internal model computation instructions that describe the dependencies
 *   and computation logic for each computed property. This can be used in future computations
 *   to efficiently compute only the necessary properties based on their dependencies.
 *
 */
export function initializeComputableModel<
  Model extends Record<string, unknown>
>(
  computableModel: Model
): {
  computedModel: Readonly<ComputedModel<Model>>;
  computationInstructions?: Array<InternalModelComputation<Model, keyof Model>>;
} {
  if (!hasComputableModelBrand(computableModel)) {
    return {
      computedModel: computableModel as Readonly<ComputedModel<Model>>,
      computationInstructions: [],
    };
  }

  const computedModel: Partial<ComputedModel<Model>> = {};
  const contextStack: ComputeContextStack = [];
  const computationInstructions = [] as Array<
    InternalModelComputation<Model, keyof Model>
  >;

  const propertiesQueue = Object.keys(computableModel) as PropertyKey[];
  const unresolvedProperties = new Map<PropertyKey, PropertyKey[]>();

  function queueProperty(property: PropertyKey) {
    propertiesQueue.push(property);
  }

  function registerUnresolvedProperty(
    property: PropertyKey,
    dependency: PropertyKey
  ) {
    const dependencies = unresolvedProperties.get(property) ?? [];
    dependencies.push(dependency);
    unresolvedProperties.set(property, dependencies);
  }

  const createInnerProxy = <T extends AnyObject | unknown[]>(
    value: T,
    currentContext: ComputeContext,
    currentProperty: PropertyKey[]
  ): T => {
    return new Proxy(value, {
      get(target, p) {
        const targetValue = hasProperty(target, p) ? target[p] : undefined;
        const currentDependency = [...currentProperty, p];
        const parentDependencyIndex = currentContext.dependencies.findIndex(
          dependency => {
            return (
              currentDependency.length === dependency.length + 1 &&
              checkEquality(dependency, currentDependency.slice(0, -1))
            );
          }
        );
        if (parentDependencyIndex !== -1) {
          currentContext.dependencies[
            parentDependencyIndex
          ] = currentDependency;
        } else {
          currentContext.dependencies.push(currentDependency);
        }

        if (isPlainObject(targetValue)) {
          return createInnerProxy(
            targetValue,
            currentContext,
            currentDependency
          );
        }

        return targetValue;
      },
    });
  };

  const proxy = new Proxy(computableModel, {
    get(target, p) {
      const property = p as keyof typeof computableModel;
      const value = target[property];
      let computation = null;

      if (typeof value === 'function') {
        const result = value(property);
        if (isComputation<Model, keyof Model>(result)) {
          computation = result;
        }
      }

      const currentContext = contextStack.at(-1);
      currentContext?.dependencies.push([property]);

      /**
       * If the model already has the property,
       * it means we are reading that value
       * to calculate some other computed property
       */
      if (currentContext && hasProperty(computedModel, property)) {
        const modelValue =
          computedModel[property as keyof typeof computedModel];

        if (isPlainObject(modelValue)) {
          return createInnerProxy(modelValue, currentContext, [property]);
        }
        return modelValue;
      }

      /**
       * If we find a situation where the model doesn't have the field
       * it means we are trying to compute a property
       * before all its dependencies are resolved.
       * On this case, we want to cancel the computation
       * and requeue it for later
       */
      if (typeof currentContext !== 'undefined') {
        currentContext.cancelled = true;
        currentContext.unresolvedProperty = property;
        throw new Error('Unresolved property');
      }

      if (computation) {
        contextStack.push({
          computationKey: computation.key,
          dependencies: [],
          cancelled: false,
        });

        try {
          const computedValue = computation.callback(
            (proxy as unknown) as Omit<ComputedModel<Model>, keyof Model>
          );
          const context = contextStack.pop();

          Object.assign(computedModel, {[property]: computedValue});

          const {dependencies} = context ?? {dependencies: []};
          computationInstructions.push(
            createInternalModelComputationDefinition(computation, dependencies)
          );

          return computedValue;
        } catch (error) {
          if (!(error instanceof Error)) {
            console.error('Unexpected error', error);
            throw new Error('Unexpected error');
          }

          if (error.message === 'Unresolved property') {
            const context = contextStack.pop();
            if (context?.cancelled) {
              registerUnresolvedProperty(
                context.computationKey,
                context.unresolvedProperty!
              );
              queueProperty(context.computationKey);
              return;
            }
          }
          throw error;
        }
      }

      Object.assign(computedModel, {[property]: value});
      return value;
    },
  });

  let property = propertiesQueue.shift();

  while (!isNil(property)) {
    /**
     * For each property, we need to check if it was requeued
     * because of unresolved dependencies.
     *
     * If that is the case, we must check if we have a circular dependency.
     * If yes, we will throw an error informing it.
     * If not, we just try to reprocess the property.
     */
    checkForCircularDependency(property);

    proxy[property as keyof typeof proxy];

    property = propertiesQueue.shift();
  }

  function checkForCircularDependency(item: PropertyKey) {
    if (unresolvedProperties.has(item)) {
      const dependencies = unresolvedProperties.get(item)!;
      dependencies.forEach(dependency => {
        const unresolvedDeps = unresolvedProperties.get(dependency);
        if (unresolvedDeps?.includes(item)) {
          throw new Error(
            `Circular dependency detected between ${String(item)} and ${String(
              dependency
            )}`
          );
        }
      });
    }
  }

  return {
    computedModel: computedModel as ComputedModel<Model>,
    computationInstructions,
  };
}

const COMPUTATION_CACHE = new Map<
  string,
  // Cache to store previously computed values for specific property sets
  {
    parameters: Array<[PropertyKey, unknown]>;
    customCheckResponse: unknown[];
    value: unknown;
  }
>();

const DEFAULT_CACHE = {
  parameters: [] as Array<[PropertyKey, unknown]>,
  customCheckResponse: [],
  value: UNCACHED_COMPUTATION_SYMBOL,
};

/**
 * Derives a function that memoizes the result
 * based on a subset of properties of a model.
 * This is useful for creating efficient,
 * memoized functions that depend on specific properties of a model.
 *
 * @param {Key[]} properties -
 *  The properties of the model that the derived function depends on.
 * @param {(values: Pick<Model, Key>) => Response} fn -
 *  The function to compute the result based on the specified properties.
 *
 * @returns {(model: Model) => Response} - The memoized function.
 */
function runComputation<
  Response,
  Model extends Record<string, unknown>,
  Key extends keyof Model
>(
  key: Key,
  properties: Array<PropertyKey[]>,
  fn: (model: Model) => Response,
  configuration: ComputeFnOptions<Model>
): (model: Model) => Response {
  const computationId = createId();
  COMPUTATION_CACHE.set(computationId, {...DEFAULT_CACHE});
  /**
   * The memoized function that computes
   * and caches results based on the specified properties.
   *
   * @param {Model} model - The model object.
   * @returns {Response} - The memoized result.
   */
  return (model: Model) => {
    const comparisonParameters = buildParametersObjectComparison(
      key,
      properties,
      model
    );

    const cache = COMPUTATION_CACHE.get(computationId)!;

    // Check if the result is already memoized for the current property set.
    if (
      isCachedValue<Model, typeof cache>(
        key,
        cache,
        model,
        comparisonParameters,
        configuration
      )
    ) {
      // Return the memoized result.
      return cache.value as Response;
    }

    // Compute the result using the provided function.
    const value = fn(model);

    // Cache the computed result for the current property set.
    COMPUTATION_CACHE.set(computationId, {
      parameters: comparisonParameters,
      customCheckResponse: configuration.cacheCheck?.(model) ?? [],
      value,
    });

    // Return the computed result.
    return value;
  };
}

function buildParametersObjectComparison<Model extends AnyObject>(
  _modelProperty: PropertyKey,
  properties: Array<PropertyKey[]>,
  model: Model
): Array<[PropertyKey, unknown]> {
  const values = properties.map(propertyArr => {
    const key = propertyArr.map(String).join('.');
    const value = propertyArr.reduce(
      (result, key) =>
        hasProperty(result, key) ? result[key as string] : undefined,
      model
    );
    return [key, value];
  });
  return values as Array<[PropertyKey, unknown]>;
}

/**
 * Checks whether a cached value is available \
 * for a specific set of parameters in the cache.
 *
 * @param {object} cache -
 *  The cache object containing the cached parameters and value.
 * @param {object} parameters -
 *  The current parameters for which to check the cache.
 *
 * @returns {boolean} -
 *  Returns `true` if a cached value is available for the given parameters;
 *  otherwise, returns `false`.
 *
 * @private
 */
function isCachedValue<
  Model extends AnyObject,
  Cache extends {
    parameters: Array<[PropertyKey, unknown]>;
    customCheckResponse: unknown[];
    value: unknown;
  }
>(
  computedProperty: PropertyKey,
  cache: Cache,
  model: Model,
  comparisonParameters: Array<[PropertyKey, unknown]>,
  configuration: ComputeFnOptions<Model>
) {
  if (cache.value === UNCACHED_COMPUTATION_SYMBOL) {
    return false;
  }
  const {cacheCheck, ignoreAssert} = configuration;

  if (cacheCheck) {
    const values = cacheCheck(model);
    const cacheCheckResult = values.every((value, index) => {
      const comparisonItem = cache.customCheckResponse.at(index);
      if (Array.isArray(value) && Array.isArray(comparisonItem)) {
        return checkEquality(value, comparisonItem);
      }
      return Object.is(value, comparisonItem);
    });
    return cacheCheckResult;
  }

  const normalCheck = comparisonParameters.every(
    ([dependency, value], index) => {
      const cached = cache.parameters?.at(index);
      const cachedValue =
        cached?.at(0) === dependency
          ? cached.at(1)
          : UNCACHED_COMPUTATION_SYMBOL;
      const isEqual =
        Array.isArray(value) && Array.isArray(cachedValue)
          ? checkEquality(value, cachedValue, (a, b) => Object.is(a, b))
          : Object.is(value, cachedValue);

      if (__DEV__ && !isEqual && !ignoreAssert) {
        logUndesiredCalculation(
          computedProperty,
          dependency,
          value,
          cachedValue
        );
      }

      return isEqual;
    }
  );

  return normalCheck;
}

/**
 * Logs undesired calculations during development.
 *
 * @param {PropertyKey} key - The key of the property for which the computation is undesired.
 * @param {unknown} value - The current value being calculated.
 * @param {unknown} cachedValue - The cached value.
 *
 * @private
 */
function logUndesiredCalculation(
  computedProperty: PropertyKey,
  dependency: PropertyKey,
  value: unknown,
  cachedValue: unknown
) {
  assert(
    () => JSON.stringify(value) !== JSON.stringify(cachedValue),
    'Immutable component doing extra work because of reference error',
    {
      data: {
        computedProperty,
        dependency,
        value,
        cache: cachedValue,
      },
    }
  );
}

/**
 * @example
 *
type ModelA = {
  a: number;
  b: number;
  c: Computed<null | string>;
  d: Computed<string>;
  e: Computed<string>;
  f: Computed<null | string>;
  g: Computed<undefined | string>;
  h: Computed<null>;
};

const a = createModel<ModelA>(compute => ({
  a: 1,
  b: 2,
  c: compute(model => null),
  d: compute(model => String(model.a * 2)),
  e: compute(model => `${model.d} ${model.c}`),
  f: compute(model => `${model.d} ${model.c}`),
  // @ts-expect-error null is invalid type
  g: compute(model => null),
  // @ts-expect-error null is invalid type
  h: compute(model => undefined),
}));

type ModelB = {a: number; b: number};
// ...inside init function
const b = createModel<ModelB>({a: 1, b: 2});

type M1 = {a: 1; b: 2; c: Computed<number>};
type M2 = ComputedModel<M1>;
type M3 = ComputedModel<M2>;
*/

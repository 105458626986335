import {IChannel} from '@v2/lib/channel';

export type ConnectedItem = unknown;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionsMap = Record<string, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ProvideMap = Record<string, any>;

// eslint-disable-next-line @typescript-eslint/prefer-function-type, @typescript-eslint/no-explicit-any
export type GenericConstructor = {new (...args: any[]): {}};

export type ActionCallback<
  TActions extends ActionsMap,
  Key extends keyof TActions = keyof TActions
> = (payload: TActions[Key]) => unknown;

/**
 * Define how an item access the value provided through the state controller
 */
export enum ConsumeMode {
  /** The item callback will run every time the provided item  */
  ON_CHANGE = 'on-change',
  /** The consumer must call a function directly  */
  ON_DEMAND = 'on-demand',
  /** Join both cases  */
  BOTH = 'both',
}

export type ConsumptionStrategy<
  TProvide extends ProvideMap,
  ProvideKey extends keyof TProvide
> = {
  mode: ConsumeMode;
  runOnInit?: boolean;
  callback: (
    value?: TProvide[ProvideKey],
    previous?: TProvide[ProvideKey]
  ) => void;
  selector?: (value?: TProvide[ProvideKey]) => unknown;
};

export interface IChannelController<
  TActions extends ActionsMap,
  TProvide extends ProvideMap
> {
  channel: IChannel;

  register(item: ConnectedItem): void;
  unregister(item: ConnectedItem): void;

  setProvider<
    T extends Record<string | symbol, unknown>,
    Key extends keyof TProvide
  >(
    item: T,
    provide: Array<{key: Key; property: TProvide[Key]}>
  ): T;

  setConsumer<Key extends keyof TProvide>(
    item: ConnectedItem,
    provideKey: string,
    strategy: ConsumptionStrategy<TProvide, Key>
  ): void;

  onAction<TActionName extends keyof TActions>(
    item: ConnectedItem,
    action: TActionName,
    callback: ActionCallback<TActions, TActionName>
  ): VoidFunction;

  offAction<TActionName extends keyof TActions>(
    item: ConnectedItem,
    action: TActionName,
    callback: ActionCallback<TActions, TActionName>
  ): void;

  emitAction<TActionName extends keyof TActions>(
    action: TActionName,
    payload?: TActions[TActionName]
  ): void;
}

export interface ISinkController<
  TActions extends ActionsMap,
  TProvide extends ProvideMap
> extends IChannelController<TActions, TProvide> {
  sink: Map<IChannel['id'], IChannel>;

  addSinkChannel(channel: IChannel): void;
  removeSinkChannel(channel: IChannel['id'] | IChannel): void;

  provide<Key extends keyof TProvide>(
    item: ConnectedItem,
    provideKey: Key,
    providedTarget: TProvide[Key]
  ): void;

  consume<Key extends keyof TProvide>(
    item: ConnectedItem,
    provideKey: string,
    strategy: ConsumptionStrategy<TProvide, Key>
  ): void;
}

import {LitElement, TemplateResult, nothing} from 'lit';
import {state} from 'lit/decorators.js';
import {AbstractConstructor, Constructor} from '@v2/types/classes';
import {MsgDefinition, VariantAtom, VariantFn} from '@v2/types/architecture';
import {FlywheelComponent} from '@v2/lib/component';
import {Nullable} from '@v2/types/general';
import {isPlainObject} from '@v2/utils';
import {v4} from 'uuid';
import {
  ComputableModel,
  ComputedModel,
  InternalModelComputation,
  initializeComputableModel,
} from './computed';

const COMMAND = Symbol('CMD');
export const EMPTY_CMD = Cmd(() => {});

/**
 * Be very careful using this.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function _AsLitElement<T extends IImmutableComponent<any, any>>(x: T) {
  return x as unknown as LitElement;
}

export type ComponentProperties<Component> = Omit<
  Map<string, unknown>,
  'get'
> & {
  get<K extends keyof Component>(key: K): Component[K];
};

export interface IImmutableComponent<Model, Msg extends VariantAtom<string>> {
  __initialized__: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: Model extends ComputableModel<any>
    ? Readonly<ComputedModel<Model>>
    : Readonly<Model>;
  handleUpdate: (command: ImmutableMsg<Msg>) => void;
  currentVersion: () => ModelVersion<Model>['id'];
  stepBack: () => void;
  stepForward: () => void;
  rerender: () => void;
  dispatchEvent: (event: Event | CustomEvent) => void;
}

export type ImmutableMsg<Msg extends VariantAtom<string>> =
  | Msg
  | (() => Msg)
  | Promise<Msg>
  | (() => Promise<Msg>);

export type ImmutableResult<Model> =
  | Readonly<Model>
  | Readonly<[Readonly<Model>, ReturnType<typeof Cmd>]>;

export function extractModel<Model>(
  result: ImmutableResult<Model>
): Readonly<Model> {
  if (isCmdResult(result)) return result[0];
  return result;
}

export function extractCmd<Model, _Cmd>(
  result: ImmutableResult<Model>
): Readonly<ReturnType<typeof Cmd>> | null {
  if (isCmdResult(result)) return result[1];
  return null;
}

/**
 * An immutable program contains every necessary part to describe
 * the life-cycle of an ImmutableComponent. The component will use
 * the program to safely handle model mutations, among other things.
 */
export interface ImmutableProgram<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Model,
  T extends VariantAtom<string>
> {
  /**
   * A function that returns the initial state of the model.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  init: <T extends Map<PropertyKey, any> = InitialProperties>(
    props: T,
    cmd: Cmd,
    model?: Readonly<ComputedModel<Model>>
  ) => ImmutableResult<ComputableModel<Model>>;
  /**
   * A function that handles all possible mutations ot a model.
   */
  update: (
    msg: T,
    model: Readonly<ComputedModel<Model>>,
    cmd: Cmd
  ) => ImmutableResult<ComputedModel<Model>>;
  /**
   * A function to replace Lit's render method. (optional)
   */
  view?: (
    model: Readonly<ComputedModel<Model>>,
    handleUpdate: (msg: ImmutableMsg<T>) => void,
    cmd: Cmd
  ) => TemplateResult | typeof nothing;
  /**
   * Used to map mutations of parent properties onto the immutable
   * data model of the current component.
   */
  extendsAdapter?: Record<string, Adapter<ComputedModel<Model>, T>>;
  /**
   * Provide addtional configuration, such as a history limit, to the
   * component.
   */
  config?: {
    historyLimit?: number;
    debug?: boolean;
  };
}

export type UpdateHandler<
  Model,
  Msg extends VariantAtom<string>
> = IImmutableComponent<ComputedModel<Model>, Msg>['handleUpdate'];

export type ViewHandler<
  Model,
  Msg extends VariantAtom<string>
> = ImmutableProgram<ComputedModel<Model>, Msg>['view'];

type Adapter<Model, T extends VariantAtom<string>> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolver: (model: ComputedModel<Model>) => any;
  variant: VariantFn<T['tag']>;
};

export type InitialProperties = Map<PropertyKey, unknown>;

type ModelVersion<Model> = {
  id: string;
  model: Readonly<Model>;
  timestamp: Date;
};

type ModelHistory<Model> = ModelVersion<Model>[];

const HISTORY_LIMIT = 10;
const ALLOWED_PROPS = FlywheelComponent.elementProperties;

const ModelVersion = <T>(model: Readonly<T>): ModelVersion<T> => {
  return {id: v4(), model, timestamp: new Date()};
};

/**
 * Creates a reusable, namespaced API for generating variants.
 */
export const makeVariants = <T extends string, U extends MsgDefinition<T>>(
  def: U
) => def;

/**
 * A curried, factory function for generating variants.
 */
export const Variant = <V>() => <T extends string>(tag: T) => (value: V) => ({
  tag,
  value,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type CmdFn<H extends IImmutableComponent<any, any>, V> = (
  host: H
) => V | void | Promise<V> | Promise<void>;

type CmdValue<T extends string, V> = (value: V) => {tag: T; value: V};

export function isCmd(value: unknown): value is ReturnType<Cmd> {
  return isPlainObject(value) && !!(value as ReturnType<Cmd>)[COMMAND];
}

export function isCmdResult<Model>(
  value: ImmutableResult<Model>
): value is Readonly<[Readonly<Model>, ReturnType<typeof Cmd>]> {
  return Array.isArray(value) && isCmd(value[1]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Cmd<H extends IImmutableComponent<any, any>, V, T extends string>(
  fn: CmdFn<H, V>,
  msg?: CmdValue<T, V>
) {
  return {
    [COMMAND]: true,
    async exec<T extends H>(host: T) {
      if (!host.__initialized__) return;

      const result = fn(host);
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!result) return;

      if (result instanceof Promise) {
        try {
          const value = await result;
          if (value && msg) host.handleUpdate(msg(value));
        } catch (err) {
          console.error(err);
          // TODO: Figure out what to do here...
        }

        return;
      }

      if (!msg) return;
      host.handleUpdate(msg(result));
    },
  };
}

/**
 * Used to stage calls to host methods or stateless functions
 * that values to be used by Msg variants.
 *
 * ```ts
 * cmd((host) => host.fetchSomething(), Msg.doSomething);
 * ```
 */
export type Cmd = typeof Cmd;

export function normalizeResult<Model>(
  result: ImmutableResult<Model>
): Readonly<[Readonly<Model>, Nullable<ReturnType<typeof Cmd>>]> {
  if (isCmdResult(result)) return result;
  return [result, null];
}

export function mergeResults<SubModel, SuperModel>(
  initial: ImmutableResult<SubModel>,
  ...results: ImmutableResult<Partial<SuperModel>>[]
): Readonly<[Readonly<SuperModel & SubModel>, ReturnType<typeof Cmd>]> {
  const [initialModel, initialCmd] = normalizeResult(initial);
  const normalized = results.map(value => normalizeResult(value));
  const models = normalized.flatMap(result => result[0]);
  const cmds = normalized
    .flatMap(result => result[1])
    .filter(cmd => cmd !== null) as ReturnType<typeof Cmd>[];

  return [
    Object.assign(initialModel, ...models) as SuperModel & SubModel,
    Cmd(host => {
      initialCmd?.exec(host);
      cmds.forEach(cmd => cmd.exec(host));
    }),
  ] as const;
}

/**
 * Helper to extend the architecture of a parent component.
 */
export function extendArchitecture<
  SuperModel,
  SuperMsg extends VariantAtom<string>,
  Model extends SuperModel,
  Msg extends VariantAtom<string | SuperMsg['tag']>
>(superProgram: ImmutableProgram<SuperModel, SuperMsg>) {
  return {
    update(
      msg: Extract<Msg, SuperMsg>,
      model: Readonly<ComputedModel<Model>>,
      cmd: Cmd
    ) {
      return superProgram.update(msg, model, cmd) as ImmutableResult<Model>;
    },
    init(props: InitialProperties, cmd: Cmd, model?: ComputedModel<Model>) {
      return superProgram.init(props, cmd, model);
    },
  };
}

export const ImmutableComponent = <
  T extends Constructor<LitElement> | AbstractConstructor<LitElement>,
  Model,
  Msg extends VariantAtom<string>
>({
  component: superClass,
  main,
}: {
  component: T;
  main: ImmutableProgram<Model, Msg>;
}) => {
  abstract class Immutable extends superClass {
    __initialized__ = false;

    protected history: ModelHistory<ComputedModel<Model>> = [];
    protected historyCursor = 0;
    protected historyLimit = main.config?.historyLimit ?? HISTORY_LIMIT;
    protected initialProperties: InitialProperties = new Map();
    protected extendedProperties: Set<string> = new Set();

    private computationInstructions: Array<
      InternalModelComputation<Record<string, unknown>, string>
    > = [];

    private internalModel: Readonly<ComputedModel<Model>>;

    @state() private __mutating__ = false;

    private cmdQueue = new Set<ReturnType<Cmd>>();

    get model(): Readonly<ComputedModel<Model>> {
      return this.internalModel as Readonly<ComputedModel<Model>>;
    }

    constructor() {
      super();
      const model = extractModel(main.init.bind(this)(new Map(), Cmd));
      this.internalModel = this.initComputableModel(model);

      if (!main.extendsAdapter) return;

      for (const key of this.extendsKeys()) {
        if (key in this) {
          this.extendedProperties.add(key);

          Object.defineProperty(this, key, {
            get() {
              return main.extendsAdapter![key].resolver(this.internalModel);
            },
            set(v) {
              this.handleUpdate(main.extendsAdapter![key].variant(v));
            },
          });
        }
      }
    }

    private extendsKeys(): string[] {
      return Object.keys(main.extendsAdapter ?? {});
    }

    private stageCmd(cmd: ReturnType<Cmd>) {
      this.cmdQueue.add(cmd);
    }

    /**
     * Performs the immutable update of the component's data model.
     * Also stages any commands for execution after update.
     *
     * @param msg The message sent by `handleUpdate` to use for updates.
     */
    private processUpdate(msg: Msg) {
      if (main.config?.debug) {
        console.debug('Received msg.', msg);
      }

      const result = main.update.bind(this)(msg, this.model, Cmd);
      const [model, cmd] = normalizeResult(result);
      const previousModel = this.internalModel;
      this.internalModel = model;
      if (previousModel !== this.internalModel) {
        this.applyComputedProperties();
      }
      cmd && this.stageCmd(cmd);

      const version = ModelVersion(this.model);

      if (main.config?.debug) {
        console.debug('Updated model.', version);
      }

      this.history =
        this.history.length >= this.historyLimit
          ? [version, ...this.history.slice(0, this.historyLimit - 1)]
          : [version, ...this.history];

      this.__mutating__ = true;
    }

    private initComputableModel(
      initialModel: Readonly<ComputableModel<Model>>
    ) {
      if (!isPlainObject(initialModel)) {
        return initialModel as Readonly<ComputedModel<Model>>;
      }

      const {
        computedModel,
        computationInstructions,
      } = initializeComputableModel(initialModel);

      if (computationInstructions) {
        // @ts-expect-error same type but inferred wrongly
        this.computationInstructions = computationInstructions;
      }

      return computedModel as Readonly<ComputedModel<Model>>;
    }

    private applyComputedProperties() {
      this.computationInstructions.forEach(({key, compute}) => {
        Object.defineProperty(this.internalModel, key, {
          value: compute(this.internalModel as Record<string, unknown>),
          writable: false,
          configurable: true,
          enumerable: true,
        });
      });
    }

    protected firstUpdated() {
      super.firstUpdated();
      this.__initialized__ = true;
    }

    protected willUpdate(changedProperties: Map<PropertyKey, unknown>) {
      super.willUpdate(changedProperties);

      if (changedProperties.has('__mutating__')) this.__mutating__ = false;

      if (this.__initialized__) {
        for (const [prop, value] of changedProperties.entries()) {
          const key = String(prop);
          if (key in (main.extendsAdapter ?? {})) {
            const adapter = main.extendsAdapter![key];
            this.handleUpdate(adapter.variant(value) as ImmutableMsg<Msg>);
          }
        }
      }
    }

    performUpdate() {
      try {
        const elementProps = new Map<string, unknown>();

        this.extendedProperties.forEach(prop => {
          if ('elementProperties' in this.constructor && this.constructor.elementProperties instanceof Map) {
            if (!this.constructor.elementProperties.has(prop)) return;

            elementProps.set(prop, this.constructor.elementProperties.get(prop));
            this.constructor.elementProperties.delete(prop);
          }

          if ('__reactivePropertyKeys' in this.constructor && this.constructor.__reactivePropertyKeys instanceof Set) {
            this.constructor.__reactivePropertyKeys.delete(prop);
          }
        });

        super.performUpdate()


        elementProps.forEach((value, key) => {
          if ('elementProperties' in this.constructor && this.constructor.elementProperties instanceof Map) {
            this.constructor.elementProperties.set(key, value);
          }

          if ('__reactivePropertyKeys' in this.constructor && this.constructor.__reactivePropertyKeys instanceof Set) {
            this.constructor.__reactivePropertyKeys.add(key);
          }
        });
      } catch (err) {
        console.error(err);
      }
    }

    protected shouldUpdate(
      changedProperties: Map<PropertyKey, unknown>
    ): boolean {
      if (!this.__initialized__) return true;
      if (changedProperties.has('__mutating__')) return this.__mutating__;
      if (this.extendsKeys().some(key => changedProperties.has(key))) return true;

      return Array.from(changedProperties.keys()).some(p => {
        return ALLOWED_PROPS.has(p);
      });
    }

    dispatchEvent(event: Event | CustomEvent) {
      super.dispatchEvent(event);
    }

    updated(changedProperties: Map<string, unknown>) {
      super.updated(changedProperties);
      for (const cmd of this.cmdQueue) {
        cmd.exec<this>(this);
        this.cmdQueue.delete(cmd);
      }
    }

    /**
     * Provides the ID of the current version of component's model.
     *
     * @returns The ID of mode's version..
     */
    currentVersion(): string {
      return this.history[this.historyCursor].id;
    }

    /**
     * Stage an update to the component's model using a Msg variant.
     *
     * ```ts
     * this.handleUpdate(Msg.setValue('value'));
     * ```
     *
     * @param message The variant to use when updating..
     */
    handleUpdate(message: ImmutableMsg<Msg>) {
      // TODO: Add validation logic.
      const handlePromise = (promise: Promise<Msg>) => {
        promise.then(msg => this.processUpdate(msg)).catch(console.error);
      };

      if (message instanceof Promise) return handlePromise(message);
      if (typeof message !== 'function') return this.processUpdate(message);

      const msg = message();
      msg instanceof Promise ? handlePromise(msg) : this.processUpdate(msg);
    }

    /**
     * Step back to the previous model version in the history list.
     */
    stepBack() {
      const index =
        this.historyCursor < this.historyLimit
          ? this.historyCursor + 1
          : this.historyLimit - 1;

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!this.history[index]) return;

      this.historyCursor = index;
      this.internalModel = this.history[this.historyCursor].model;
      this.__mutating__ = true;
    }

    /**
     * Step forward to the next version in the history list.
     * (Note: if you modify the model after having previously stepped
     * stepped back, you should expect the subsequent step to have changed.)
     */
    stepForward() {
      const index = this.historyCursor > 0 ? this.historyCursor - 1 : 0;
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!this.history[index]) return;

      this.historyCursor = index;
      this.internalModel = this.history[this.historyCursor].model;
      this.__mutating__ = true;
    }

    rerender() {
      this.__mutating__ = true;
    }

    connectedCallback(): void {
      super.connectedCallback();

      this.initialProperties = new Map(
        Array.from(
          (this.constructor as typeof LitElement).elementProperties ?? []
        )
          .filter(([key]) => key in this)
          .map(([key]) => {
            return [key, this[key as keyof Immutable]];
          })
      );

      const result = main.init.bind(this)(
        this.initialProperties,
        Cmd,
        this.model
      );

      const model = extractModel(result);
      this.internalModel = this.initComputableModel(model);
      if (isCmdResult(result)) this.stageCmd(result[1]);

      this.history = [ModelVersion(this.internalModel)];
    }

    render() {
      if (main.view) {
        return main.view.bind(this)(
          this.internalModel,
          this.handleUpdate.bind(this),
          Cmd
        );
      }
    }
  }

  type ImmutableMixin = AbstractConstructor<IImmutableComponent<Model, Msg>> &
    T;
  return (Immutable as unknown) as ImmutableMixin;
};

// Helpers
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function chainCmd<Host extends IImmutableComponent<any, any>, V>(
  ...commands: Array<CmdFn<Host, V>>
): (host: Host) => void {
  return function (host: Host) {
    commands.forEach(cmd => cmd(host));
  };
}

export function modelGetter<Model, Key extends keyof Model>(
  host: {model: Model},
  modelKey: Key
): Model[Key] {
  return host.model[modelKey];
}

export function modelSetter<Msg extends VariantAtom<string>>() {
  return function <
    Component extends FlywheelComponent & {
      handleUpdate: (command: ImmutableMsg<Msg>) => void;
    },
    Key extends keyof Component
  >(host: Component, hostKey: Key) {
    return (props: {value: Component[Key]; msg: ImmutableMsg<Msg>}) => {
      const oldValue = host[hostKey];
      if (props.value !== oldValue) {
        host.handleUpdate(props.msg);
        host.requestUpdate(hostKey, oldValue);
      }
    };
  };
}

import {LitElement} from 'lit';
import {StyleInfo} from 'lit/directives/style-map.js';
import {FlywheelComponent} from '@v2/lib/component';
import {isNumber} from './index';
import {hasProperty} from './objects';

/**
 * Attach event listeners to a slot's assigned elements.
 */
export function watchAssignedElements<T = CustomEvent>(
  slot: HTMLSlotElement,
  eventKey: string,
  listener: (e: T | Event) => void,
  queryAll?: string
): void {
  const watchedNodes: Element[] = [];

  function slotChangeHandler() {
    const elements = slot.assignedElements().filter(el => {
      return (
        !watchedNodes.some(n => n.isSameNode(el)) &&
        (queryAll ? el.matches(queryAll) : true)
      );
    });

    elements.forEach(el => {
      el.addEventListener(eventKey, listener);
      watchedNodes.push(el);
    });
  }

  slot.addEventListener('slotchange', slotChangeHandler);
}

/**
 * Returns an element's offset relative to its parent. Similar to element.offsetTop and element.offsetLeft
 */
export function getOffset(element: HTMLElement, parent: HTMLElement) {
  return {
    top: Math.round(
      element.getBoundingClientRect().top - parent.getBoundingClientRect().top
    ),
    left: Math.round(
      element.getBoundingClientRect().left - parent.getBoundingClientRect().left
    ),
  };
}

/**
 *
 * Scrolls an element into view of its container. If the element is already in view, nothing will happen.
 */
export function scrollIntoView(
  element: HTMLElement,
  container: HTMLElement,
  direction: 'horizontal' | 'vertical' | 'both' = 'vertical',
  behavior: 'smooth' | 'auto' = 'smooth'
) {
  const offset = getOffset(element, container);
  const offsetTop = offset.top + container.scrollTop;
  const offsetLeft = offset.left + container.scrollLeft;
  const minX = container.scrollLeft;
  const maxX = container.scrollLeft + container.offsetWidth;
  const minY = container.scrollTop;
  const maxY = container.scrollTop + container.offsetHeight;

  if (direction === 'horizontal' || direction === 'both') {
    if (offsetLeft < minX) {
      container.scrollTo({left: offsetLeft, behavior});
    } else if (offsetLeft + element.clientWidth > maxX) {
      container.scrollTo({
        left: offsetLeft - container.offsetWidth + element.clientWidth,
        behavior,
      });
    }
  }

  if (direction === 'vertical' || direction === 'both') {
    if (offsetTop < minY) {
      container.scrollTo({top: offsetTop, behavior});
    } else if (offsetTop + element.clientHeight > maxY) {
      container.scrollTo({
        top: offsetTop - container.offsetHeight + element.clientHeight,
        behavior,
      });
    }
  }
}

/**
 * Simulates :focus-visible behavior on an element by watching for certain keyboard and mouse heuristics and toggling a
`focus-visible` class.
 */
const focusVisibleListeners = new WeakMap();

function observe(el: HTMLElement) {
  const keys = [
    'Tab',
    'ArrowUp',
    'ArrowDown',
    'ArrowLeft',
    'ArrowRight',
    'Home',
    'End',
    'PageDown',
    'PageUp',
  ];
  const is = (event: KeyboardEvent) => {
    if (keys.includes(event.key)) {
      el.classList.add('focus-visible');
    }
  };
  const isNot = () => el.classList.remove('focus-visible');
  focusVisibleListeners.set(el, {is, isNot});

  el.addEventListener('keydown', is);
  el.addEventListener('keyup', is);
  el.addEventListener('mousedown', isNot);
  el.addEventListener('mousedown', isNot);
}

function unobserve(el: HTMLElement) {
  const {is, isNot} = focusVisibleListeners.get(el);

  el.classList.remove('focus-visible');
  el.removeEventListener('keydown', is);
  el.removeEventListener('keyup', is);
  el.removeEventListener('mousedown', isNot);
  el.removeEventListener('mousedown', isNot);
}

export const focusVisible = {
  observe,
  unobserve,
};

/**
 *  @watch decorator
 * Runs when an observed property changes, e.g. @property or @state, but before the component updates.
 * To wait for an update to complete after a change occurs, use `await this.updateComplete` in the handler. To start
 * watching after the initial update/render, use `{ waitUntilFirstUpdate: true }` or `this.hasUpdated` in the handler.
 * Usage:
 * @watch('propName')
 * handlePropChange(oldValue, newValue) {
 *   ...
 * }
 * */
interface WatchOptions {
  waitUntilFirstUpdate?: boolean;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function watch(propName: string, options?: WatchOptions) {
  return (protoOrDescriptor: any, name: string): any => {
    const {update} = protoOrDescriptor;

    options = Object.assign(
      {waitUntilFirstUpdate: false},
      options
    ) as WatchOptions;

    protoOrDescriptor.update = function (changedProps: Map<string, any>) {
      if (changedProps.has(propName)) {
        const oldValue = changedProps.get(propName);
        const newValue = this[propName];

        if (oldValue !== newValue) {
          if (!options?.waitUntilFirstUpdate || this.hasUpdated) {
            this[name].call(this, oldValue, newValue);
          }
        }
      }

      update.call(this, changedProps);
    };
  };
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export function getActiveElement(
  root: Document | ShadowRoot = document
): Element | null {
  const activeEl = root.activeElement;

  if (!activeEl) {
    return null;
  }

  if (activeEl.shadowRoot) {
    return getActiveElement(activeEl.shadowRoot);
  } else {
    return activeEl;
  }
}

/**
 * Fires callback only when DOM is ready.
 */
export function whenReady(callback: () => void) {
  if (/complete|interactive|loaded/.test(document.readyState)) {
    callback();
  } else {
    document.addEventListener('DOMContentLoaded', callback, false);
  }
}

export function isHTMLElement(node: unknown): node is HTMLElement {
  return (
    !!node &&
    hasProperty(node, 'nodeType', isNumber) &&
    node.nodeType === Node.ELEMENT_NODE &&
    node instanceof HTMLElement
  );
}

export function isLitElement(node: unknown): node is LitElement {
  return (
    !!node &&
    hasProperty(node, 'nodeType', isNumber) &&
    node.nodeType === Node.ELEMENT_NODE &&
    node instanceof LitElement
  );
}

type BoundedListenerGenerator<T extends Event | CustomEvent> = {
  add: (
    eventKey: string,
    listener: (e: T) => void
  ) => BoundedListenerGenerator<T>;
};

export function listenerGenerator<T extends Event | CustomEvent>(
  element: Element
): BoundedListenerGenerator<T> {
  const response: BoundedListenerGenerator<T> = {
    add: (eventKey: string, listener: (e: T) => void) => {
      element.addEventListener(eventKey, e => {
        listener(e as T);
      });

      return listenerGenerator<T>(element);
    },
  };
  return response;
}

export function getDevicePixelRatio(): number {
  const dpr = 'devicePixelRatio' in window ? window.devicePixelRatio : 1;
  return dpr || 1;
}

export function isDeviceHighPixelRatio(): boolean {
  return getDevicePixelRatio() >= 2;
}

export function roundByDevicePixelRatio(value: number): number {
  const dpr = getDevicePixelRatio();
  return Math.round(value * dpr) / dpr;
}

export function toCssValue(value: number, unit = 'px'): string {
  return value === 0 ? '0' : `${value}${unit}`;
}

export function applyStylesToElement(
  element: HTMLElement,
  style: StyleInfo
): void {
  for (const [property, value] of Object.entries(style)) {
    if (value) {
      element.style.setProperty(property, String(value));
    } else {
      element.style.removeProperty(property);
    }
  }
}

export function whenComponentIsReady<
  Component extends FlywheelComponent,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Fn extends (...args: any[]) => any
>(component: Component, callback: Fn): VoidFunction {
  if (component.lifecycleComplete) {
    callback();
  } else {
    component.addEventListener('rendered', callback, {once: true});
  }

  return () => {
    component.removeEventListener('rendered', callback);
  };
}
